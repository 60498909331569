import { createRouter, createWebHistory } from 'vue-router'
import startLocation from '../views/startLocation.vue'

const routes = [
  {
    path: '/',
    name: 'startLocation',
    component: startLocation
  },
  {
    path: '/go/:ref',
    name: 'shorlinks',
    component: () => import(/* wbpackChunkName "login" */ '../views/shorlinks.vue')
  },
  {
    path: '/:browserCountry/:userLang/',
    name: 'homeLang',
    component: () => import(/* wbpackChunkName "login" */ '../views/HomeView.vue')
  },
  {
    path: '/:browserCountry/:userLang/logout',
    name: 'logout',
    component: () => import(/* wbpackChunkName "login" */ '../views/logout.vue')
  },
  {
    path: '/:browserCountry/:userLang/professionals-list/:ref/:listType',
    name: 'professionals-list',
    component: () => import(/* wbpackChunkName "login" */ '../views/public/professionals-list.vue')
  },
  {
    path: '/:browserCountry/:userLang/comunity',
    name: 'comunity',
    component: () => import(/* wbpackChunkName "login" */ '../views/public/comunity.vue')
  },
  {
    path: '/:browserCountry/:userLang/terms',
    name: 'terms',
    component: () => import(/* wbpackChunkName "login" */ '../views/public/terms.vue')
  },
  {
    path: '/:browserCountry/:userLang/privacy',
    name: 'privacy',
    component: () => import(/* wbpackChunkName "login" */ '../views/public/privacy.vue')
  },
  {
    path: '/:browserCountry/:userLang/certificate/:ref',
    name: 'certificate',
    component: () => import(/* wbpackChunkName "login" */ '../views/public/certificate.vue')
  },
  {
    path: '/:browserCountry/:userLang/login',
    name: 'login',
    component: () => import(/* wbpackChunkName "login" */ '../views/login.vue')
  },
  {
    path: '/:browserCountry/:userLang/login-cart',
    name: 'login-cart',
    component: () => import(/* wbpackChunkName "login" */ '../views/login-cart.vue')
  },
  {
    path: '/:browserCountry/:userLang/authenticate',
    name: 'login-no-back',
    component: () => import(/* wbpackChunkName "login" */ '../views/login-no-back.vue')
  },
  {
    path: '/:browserCountry/:userLang/register/:ref?/:ref2?',
    name: 'register',
    component: () => import(/* wbpackChunkName "register" */ '../views/register.vue')
  },
  {
    path: '/:browserCountry/:userLang/recruiters',
    name: 'registerTecruiter',
    component: () => import(/* wbpackChunkName "register" */ '../views/register-recruiter.vue')
  },
  {
    path: '/:browserCountry/:userLang/signin',
    name: 'signin',
    component: () => import(/* wbpackChunkName "register" */ '../views/signin.vue')
  },
  {
    path: '/:browserCountry/:userLang/user-cart',
    name: 'loginsignin',
    component: () => import(/* wbpackChunkName "register" */ '../views/login-signin.vue')
  },
  {
    path: '/:browserCountry/:userLang/recovery-account',
    name: 'recovery-account',
    component: () => import(/* wbpackChunkName "recovery-account" */ '../views/recovery-account.vue')
  },
  {
    path: '/:browserCountry/:userLang/change-password/:ref?',
    name: 'change-password',
    component: () => import(/* wbpackChunkName "change-password" */ '../views/my-account/change-password.vue')
  },
  {
    path: '/:browserCountry/:userLang/change-avatar',
    name: 'change-avatar',
    component: () => import(/* wbpackChunkName "change-avatar" */ '../views/my-account/change-avatar.vue')
  },
  {
    path: '/:browserCountry/:userLang/change-logo-square',
    name: 'change-logo-square',
    component: () => import(/* wbpackChunkName "change-logo-square" */ '../views/my-account/change-logo-square.vue')
  },
  {
    path: '/:browserCountry/:userLang/change-logo-rectangle',
    name: 'change-logo-rectangle',
    component: () => import(/* wbpackChunkName "change-logo-rectangle" */ '../views/my-account/change-logo-rectangle.vue')
  },
  {
    path: '/:browserCountry/:userLang/change-sign',
    name: 'change-sign',
    component: () => import(/* wbpackChunkName "change-sign" */ '../views/my-account/change-sign.vue')
  },
  {
    path: '/:browserCountry/:userLang/change-social-networks',
    name: 'change-social-networks',
    component: () => import(/* wbpackChunkName "change-social-networks" */ '../views/my-account/change-social-networks.vue')
  },
  {
    path: '/:browserCountry/:userLang/my-account/:ref?',
    name: 'my-account',
    component: () => import(/* wbpackChunkName "my-account" */ '../views/my-account.vue')
  },
  {
    path: '/:browserCountry/:userLang/change-user-info',
    name: 'change-user-info',
    component: () => import(/* wbpackChunkName "change-user-info" */ '../views/my-account/change-user-info.vue')
  },
  {
    path: '/:browserCountry/:userLang/change-user-address',
    name: 'change-user-address',
    component: () => import(/* wbpackChunkName "change-user-address" */ '../views/my-account/change-user-address.vue')
  },
  {
    path: '/:browserCountry/:userLang/change-user-email',
    name: 'change-user-email',
    component: () => import(/* wbpackChunkName "change-user-email" */ '../views/my-account/change-user-email.vue')
  },
  {
    path: '/:browserCountry/:userLang/wallet-account',
    name: 'change-user-wallet',
    component: () => import(/* wbpackChunkName "change-user-wallet" */ '../views/my-account/change-user-wallet.vue')
  },
  {
    path: '/:browserCountry/:userLang/change-business-name',
    name: 'change-business-name',
    component: () => import(/* wbpackChunkName "change-business-name" */ '../views/my-account/change-business-name.vue')
  },
  {
    path: '/:browserCountry/:userLang/change-user-bio',
    name: 'change-user-bio',
    component: () => import(/* wbpackChunkName "change-user-bio" */ '../views/my-account/change-user-bio.vue')
  },
  {
    path: '/:browserCountry/:userLang/verify-email/:ref',
    name: 'verify-email',
    component: () => import(/* wbpackChunkName "verify-email" */ '../views/verify-email.vue')
  },
  {
    path: '/:browserCountry/:userLang/change-user-phone',
    name: 'change-user-phone',
    component: () => import(/* wbpackChunkName "change-user-phone" */ '../views/my-account/change-user-phone.vue')
  },
  {
    path: '/:browserCountry/:userLang/change-nickname',
    name: 'change-nickname',
    component: () => import(/* wbpackChunkName "change-nickname" */ '../views/my-account/change-nickname.vue')
  },
  {
    path: '/:browserCountry/:userLang/change-user-bank',
    name: 'change-user-bank',
    component: () => import(/* wbpackChunkName "change-user-bank" */ '../views/my-account/change-user-bank.vue')
  },
  {
    path: '/:browserCountry/:userLang/change-global-payments/:ref?',
    name: 'change-global-payments',
    component: () => import(/* wbpackChunkName "change-global-payments" */ '../views/my-account/change-global-payments.vue')
  },
  {
    path: '/:browserCountry/:userLang/change-local-payments/:ref?',
    name: 'change-local-payments',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/my-account/change-local-payments.vue')
  },
  {
    path: '/:browserCountry/:userLang/change-user-certificate',
    name: 'change-user-certificate',
    component: () => import(/* wbpackChunkName "change-user-certificate" */ '../views/my-account/change-user-certificate.vue')
  },
  {
    path: '/:browserCountry/:userLang/change-calendar',
    name: 'change-calendar',
    component: () => import(/* wbpackChunkName "change-calendar" */ '../views/my-account/change-calendar.vue')
  },
  {
    path: '/:browserCountry/:userLang/connect-zoom',
    name: 'connect-zoom',
    component: () => import(/* wbpackChunkName "connect-zoom" */ '../views/my-account/connect-zoom.vue')
  },
  {
    path: '/:browserCountry/:userLang/user-web',
    name: 'user-web',
    component: () => import(/* wbpackChunkName "user-web" */ '../views/my-account/user-web.vue')
  },
  {
    path: '/:browserCountry/:userLang/biometric',
    name: 'biometric',
    component: () => import(/* wbpackChunkName "biometric" */ '../views/biometric/biometric.vue')
  },
  //edicion de servicios
  {
    path: '/:browserCountry/:userLang/new-service',
    name: 'new-service',
    component: () => import(/* wbpackChunkName "page-editor" */ '../views/edit-service/new-service.vue')
  },
  {
    path: '/:browserCountry/:userLang/general-service/:ref/:type?',
    name: 'general-service',
    component: () => import(/* wbpackChunkName "general-service" */ '../views/edit-service/general-service.vue')
  },
  {
    path: '/:browserCountry/:userLang/edit-service/:ref',
    name: 'edit-service',
    component: () => import(/* wbpackChunkName "edit-service" */ '../views/edit-service/edit-service.vue')
  },
  {
    path: '/:browserCountry/:userLang/edit-turns/:ref',
    name: 'edit-turns',
    component: () => import(/* wbpackChunkName "edit-turns" */ '../views/edit-service/edit-turns.vue')
  },
  {
    path: '/:browserCountry/:userLang/edit-turns-recurrents/:ref',
    name: 'edit-turns-recurrents',
    component: () => import(/* wbpackChunkName "edit-turns" */ '../views/edit-service/edit-turns-recurrents.vue')
  },
  {
    path: '/:browserCountry/:userLang/blocked-days',
    name: 'blocked-days',
    component: () => import(/* wbpackChunkName "blocked-days" */ '../views/edit-service/blocked-days.vue')
  },




  {
    path: '/:browserCountry/:userLang/general-workshop/:ref/:type?',
    name: 'general-workshop',
    component: () => import(/* wbpackChunkName "general-service" */ '../views/edit-service/general-workshop.vue')
  },
  {
    path: '/:browserCountry/:userLang/edit-workshop/:ref',
    name: 'edit-workshop',
    component: () => import(/* wbpackChunkName "general-service" */ '../views/edit-service/edit-workshop.vue')
  },
  {
    path: '/:browserCountry/:userLang/manage-quiz',
    name: 'manage-quiz',
    component: () => import(/* wbpackChunkName "blocked-days" */ '../views/my-account/edit-quiz/manage-quiz.vue')
  },
  {
    path: '/:browserCountry/:userLang/manage-questions/:ref',
    name: 'manage-questions',
    component: () => import(/* wbpackChunkName "blocked-days" */ '../views/my-account/edit-quiz/manage-question.vue')
  },
  {
    path: '/:browserCountry/:userLang/manage-modules/:ref',
    name: 'manage-modules',
    component: () => import(/* wbpackChunkName "blocked-days" */ '../views/my-account/edit-modules/manage-modules.vue')
  },

  



  {
    path: '/:browserCountry/:userLang/my-tools/:ref?',
    name: 'my-tools',
    component: () => import(/* wbpackChunkName "my-tools" */ '../views/my-tools.vue')
  },
  {
    path: '/:browserCountry/:userLang/recruiter-tools',
    name: 'my-tools-recruiter',
    component: () => import(/* wbpackChunkName "my-tools" */ '../views/my-tools-recruiter.vue')
  },
  {
    path: '/:browserCountry/:userLang/coupons-recruiter',
    name: 'coupons-recruiter',
    component: () => import(/* wbpackChunkName "my-tools" */ '../views/my-account/coupons-recruiter.vue')
  },
  {
    path: '/:browserCountry/:userLang/referals',
    name: 'referals',
    component: () => import(/* wbpackChunkName "my-tools" */ '../views/my-account/referals.vue')
  },
  {
    path: '/:browserCountry/:userLang/service/:ref/:title?',
    name: 'service',
    component: () => import(/* wbpackChunkName "service" */ '../views/service.vue')
  },
  {
    path: '/:browserCountry/:userLang/service-landing/:ref/:title?',
    name: 'service-landing',
    component: () => import(/* wbpackChunkName "service" */ '../views/service-landing.vue')
  },
  {
    path: '/:browserCountry/:userLang/course/:ref/:title?',
    name: 'course',
    component: () => import(/* wbpackChunkName "service" */ '../views/course.vue')
  },
  {
    path: '/:browserCountry/:userLang/course-landing/:ref/:title?/:gift?',
    name: 'course-landing',
    component: () => import(/* wbpackChunkName "service" */ '../views/course-landing.vue')
  },
  {
    path: '/:browserCountry/:userLang/imgs-editor',
    name: 'imgs-editor',
    component: () => import(/* wbpackChunkName "imgs-editor" */ '../views/edit-service/imgs-editor.vue')
  },
  {
    path: '/:browserCountry/:userLang/questions',
    name: 'questions',
    component: () => import(/* wbpackChunkName "questions" */ '../views/questions/questions.vue')
  },
  {
    path: '/:browserCountry/:userLang/answers',
    name: 'answers',
    component: () => import(/* wbpackChunkName "answers" */ '../views/questions/answers.vue')
  },
  {
    path: '/:browserCountry/:userLang/success-question',
    name: 'success-question',
    component: () => import(/* wbpackChunkName "success-question" */ '../views/questions/success-question.vue')
  },
  {
    path: '/:browserCountry/:userLang/autologin/:ref/:go/:id?/:pay?',
    name: 'autologin',
    component: () => import(/* wbpackChunkName "autologin" */ '../views/autologin.vue')
  },
  {
    path: '/:browserCountry/:userLang/confirmation/:ref?/:idPay?/:tempBookId?',
    name: 'confirmation-cart',
    component: () => import(/* wbpackChunkName "autologin" */ '../views/cart/confirmation.vue')
  },
  {
    path: '/:browserCountry/:userLang/confirmation-landing/:ref?/:idPay?/:tempBookId?',
    name: 'confirmation-cart-landing',
    component: () => import(/* wbpackChunkName "autologin" */ '../views/cart/confirmation-landing.vue')
  },
  {
    path: '/:browserCountry/:userLang/cash-success',
    name: 'cash-success',
    component: () => import(/* wbpackChunkName "autologin" */ '../views/cart/cash-success.vue')
  },
  {
    path: '/:browserCountry/:userLang/success',
    name: 'transfer-success',
    component: () => import(/* wbpackChunkName "autologin" */ '../views/cart/transfer-success.vue')
  },
  {
    path: '/:browserCountry/:userLang/free-success',
    name: 'free-success',
    component: () => import(/* wbpackChunkName "autologin" */ '../views/cart/free-success.vue')
  },
  {
    path: '/:browserCountry/:userLang/free-success-course',
    name: 'free-success-course',
    component: () => import(/* wbpackChunkName "autologin" */ '../views/cart/free-success-course.vue')
  },
  {
    path: '/:browserCountry/:userLang/pay-success',
    name: 'card-success',
    component: () => import(/* wbpackChunkName "autologin" */ '../views/cart/card-success.vue')
  },
  {
    path: '/:browserCountry/:userLang/pay-success-course',
    name: 'card-success-course',
    component: () => import(/* wbpackChunkName "autologin" */ '../views/cart/card-success-course.vue')
  },
  {
    path: '/:browserCountry/:userLang/manual-success',
    name: 'manual-success',
    component: () => import(/* wbpackChunkName "autologin" */ '../views/cart/manual-success.vue')
  },
  {
    path: '/:browserCountry/:userLang/refound',
    name: 'card-refound',
    component: () => import(/* wbpackChunkName "autologin" */ '../views/cart/card-refound.vue')
  },
  {
    path: '/:browserCountry/:userLang/confirm-price',
    name: 'confirm-price',
    component: () => import(/* wbpackChunkName "autologin" */ '../views/cart/confirm-price.vue')
  },
  {
    path: '/:browserCountry/:userLang/decline-price',
    name: 'decline-price',
    component: () => import(/* wbpackChunkName "autologin" */ '../views/cart/decline-price.vue')
  },
  {
    path: '/:browserCountry/:userLang/booking-not-found',
    name: 'booking-not-found',
    component: () => import(/* wbpackChunkName "autologin" */ '../views/cart/booking-not-found.vue')
  },
  {
    path: '/:browserCountry/:userLang/bookings/:ref?/:idPay?',
    name: 'bookings',
    component: () => import(/* wbpackChunkName "my-bookings" */ '../views/bookings/bookings.vue')
  },
  {
    path: '/:browserCountry/:userLang/my-bookings/:ref?/:idPay?',
    name: 'my-bookings',
    component: () => import(/* wbpackChunkName "my-bookings" */ '../views/bookings/my-bookings.vue')
  },
  {
    path: '/:browserCountry/:userLang/next-session/:ref',
    name: 'next-session',
    component: () => import(/* wbpackChunkName "my-bookings" */ '../views/bookings/next-session.vue')
  },
  {
    path: '/:browserCountry/:userLang/pay-service/:ref/:idPay/:tempBookId',
    name: 'pay-service',
    component: () => import(/* wbpackChunkName "my-bookings" */ '../views/bookings/pay-service.vue')
  },
  {
    path: '/:browserCountry/:userLang/my-courses/:ref?/:idPay?/:tempBookId?',
    name: 'my-courses',
    component: () => import(/* wbpackChunkName "my-bookings" */ '../views/bookings/my-courses.vue')
  },
  {
    path: '/:browserCountry/:userLang/courses/:ref?/:idPay?',
    name: 'courses',
    component: () => import(/* wbpackChunkName "my-bookings" */ '../views/bookings/courses.vue')
  },
  {
    path: '/:browserCountry/:userLang/calificate/:ref',
    name: 'client-calification',
    component: () => import(/* wbpackChunkName "my-bookings" */ '../views/califications/client-calification.vue')
  },
  {
    path: '/:browserCountry/:userLang/califications/:ref',
    name: 'seller-calification',
    component: () => import(/* wbpackChunkName "my-bookings" */ '../views/califications/seller-calification.vue')
  },
  {
    path: '/:browserCountry/:userLang/cancelation/:ref',
    name: 'client-cancelation',
    component: () => import(/* wbpackChunkName "my-bookings" */ '../views/cancelations/client-cancelation.vue')
  },
  {
    path: '/:browserCountry/:userLang/cancelations/:ref',
    name: 'seller-cancelation',
    component: () => import(/* wbpackChunkName "my-bookings" */ '../views/cancelations/seller-cancelation.vue')
  },
  {
    path: '/:browserCountry/:userLang/remark/:ref',
    name: 'client-remark',
    component: () => import(/* wbpackChunkName "my-bookings" */ '../views/cancelations/client-remark.vue')
  },
  {
    path: '/:browserCountry/:userLang/remarks/:ref',
    name: 'seller-remark',
    component: () => import(/* wbpackChunkName "my-bookings" */ '../views/cancelations/seller-remark.vue')
  },
  {
    path: '/:browserCountry/:userLang/chat/:ref?',
    name: 'chat',
    component: () => import(/* wbpackChunkName "my-bookings" */ '../views/chat/chat.vue')
  },
  {
    path: '/:browserCountry/:userLang/chats/:ref?',
    name: 'prof-chat',
    component: () => import(/* wbpackChunkName "my-bookings" */ '../views/chat/prof-chat.vue')
  },
  {
    path: '/:browserCountry/:userLang/tickets/:ref?',
    name: 'tickets',
    component: () => import(/* wbpackChunkName "my-bookings" */ '../views/clients/tickets.vue')
  },
  {
    path: '/:browserCountry/:userLang/clients/:ref?',
    name: 'clients',
    component: () => import(/* wbpackChunkName "my-bookings" */ '../views/clients/clients.vue')
  },
  {
    path: '/:browserCountry/:userLang/assistant/:ref?',
    name: 'assistant',
    component: () => import(/* wbpackChunkName "page-editor" */ '../views/assistant.vue')
  },
  {
    path: '/:browserCountry/:userLang/plans/:ref?/:idPay?',
    name: 'change-plan',
    component: () => import(/* wbpackChunkName "page-editor" */ '../views/plans/change-plan.vue')
  },
  {
    path: '/:browserCountry/:userLang/professional/:ref',
    name: 'professional',
    component: () => import(/* wbpackChunkName "page-editor" */ '../views/professional.vue')
  },
  {
    path: '/:browserCountry/:userLang/room/:ref/:group?/:external?',
    name: 'room',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/room/room.vue')
  },
  {
    path: '/:browserCountry/:userLang/course-room/:ref',
    name: 'course-room',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/room/course-room.vue')
  },
  {
    path: '/:browserCountry/:userLang/search/:type?/:ref?/:country?/:state?/:city?/:langEs?/:langEn?/:langPo?/:langFr?/:langIt?/:langAl?/:today?/:gender?',
    name: 'search',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/search/search.vue')
  },
  {
    path: '/:browserCountry/:userLang/terapias/:type?/:ref?/:country?/:state?/:city?/:langEs?/:langEn?/:langPo?/:langFr?/:langIt?/:langAl?/:today?/:gender?',
    name: 'busqueda',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/search/search.vue')
  },
  {
    path: '/:browserCountry/:userLang/sintomas/:type?/:ref?/:country?/:state?/:city?/:langEs?/:langEn?/:langPo?/:langFr?/:langIt?/:langAl?/:today?/:gender?',
    name: 'busqueda-sintomas',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/search/search.vue')
  },
  {
    path: '/:browserCountry/:userLang/getSellerPublish/:ref/:ref2/:colorActive?',
    name: 'getSellerPublish',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/search/getSellerPublish.vue')
  },
  {
    path: '/:browserCountry/:userLang/billing',
    name: 'billing',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/my-account/billing.vue')
  },
  {
    path: '/:browserCountry/:userLang/my-profile',
    name: 'my-profile',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/my-account/my-profile.vue')
  },
  {
    path: '/:browserCountry/:userLang/my-services',
    name: 'my-services',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/my-account/my-services.vue')
  },
  {
    path: '/:browserCountry/:userLang/my-business',
    name: 'my-business',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/my-account/my-business.vue')
  },
  {
    path: '/:browserCountry/:userLang/my-coupons',
    name: 'my-coupons',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/my-account/my-coupons.vue')
  },
  {
    path: '/:browserCountry/:userLang/my-workshops',
    name: 'my-workshops',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/my-account/my-workshops.vue')
  },
  {
    path: '/:browserCountry/:userLang/my-charges',
    name: 'my-charges',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/my-account/my-charges.vue')
  },
  {
    path: '/:browserCountry/:userLang/my-plan/:ref?/:idPay?',
    name: 'my-plan',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/my-account/my-plan.vue')
  },
  {
    path: '/:browserCountry/:userLang/plan-status/:ref?/:ref2?',
    name: 'plan-status',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/my-account/plan-status.vue')
  },
  {
    path: '/:browserCountry/:userLang/config',
    name: 'sidebar-start-mobile',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/my-account/sidebar-start-mobile.vue')
  },
  {
    path: '/:browserCountry/:userLang/qr-reader',
    name: 'qr',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/qr/qr-reader.vue')
  },
  {
    path: '/:browserCountry/:userLang/lesson/:ref/:book?/:ref2?/:idPay?/:tempBookId?',
    name: 'lesson',
    component: () => import(/* wbpackChunkName "my-bookings" */ '../views/bookings/lesson.vue')
  },
  {
    path: '/:browserCountry/:userLang/qr-reader-live-date/:lessonId/:userId',
    name: 'qr-reader-live-date',
    component: () => import(/* wbpackChunkName "my-bookings" */ '../views/qr/qr-reader-live-date.vue')
  },
  {
    path: '/:browserCountry/:userLang/meeting/:room?/:type?/:date?',
    name: 'meeting',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/room/meeting.vue')
  },
  {
    path: '/:browserCountry/:userLang/meet-room/:room?/:date?/:user?',
    name: 'meeting-recruiter',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/room/meeting-recruiter.vue')
  },
  {
    path: '/:browserCountry/:userLang/prueba-pdf',
    name: 'prueba-pdf',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/prueba-pdf.vue')
  },
  {
    path: '/:browserCountry/:userLang/version',
    name: 'version',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/version.vue')
  },
  {
    path: '/:browserCountry/:userLang/add-credit-card',
    name: 'add-credit-card',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/add-credit-card.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
